var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "상세" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: true,
                            label: "설비명",
                            name: "equipmentName",
                          },
                          model: {
                            value: _vm.data.equipmentName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "equipmentName", $$v)
                            },
                            expression: "data.equipmentName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: true,
                            label: "자재명",
                            name: "equipmentBomName",
                          },
                          model: {
                            value: _vm.data.equipmentBomName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "equipmentBomName", $$v)
                            },
                            expression: "data.equipmentBomName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            disabled: true,
                            editable: _vm.editable,
                            codeGroupCd: "MATERIAL_UNIT_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "materialUnitCd",
                            label: "단위",
                          },
                          model: {
                            value: _vm.data.materialUnitCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "materialUnitCd", $$v)
                            },
                            expression: "data.materialUnitCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "현재 재고량",
                            type: "number",
                            name: "realStock",
                          },
                          model: {
                            value: _vm.realStock,
                            callback: function ($$v) {
                              _vm.realStock = $$v
                            },
                            expression: "realStock",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
            [
              _c(
                "c-table",
                {
                  ref: "grid1",
                  attrs: {
                    title: "부품 입고 이력",
                    columns: _vm.grid1.columns,
                    data: _vm.data.equipInList,
                    gridHeight: _vm.grid1.height,
                    editable: _vm.editable && !_vm.disabled,
                    pagePerRow: { pageNumber: 10 },
                    hideBottom: false,
                    usePaging: true,
                    isExcelDown: true,
                    filtering: true,
                    isFullScreen: true,
                    columnSetting: false,
                    topBorderClass: "topcolor-orange",
                    selection: "multiple",
                    rowKey: "equipmentInCd",
                  },
                  on: { "table-data-change": _vm.tableDataChangeIn },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && _vm.popupParam.equipmentBomCd
                            ? _c("c-btn", {
                                attrs: { label: "추가", icon: "add" },
                                on: { btnClicked: _vm.addRowIn },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.data.equipInList.length > 0
                            ? _c("c-btn", {
                                attrs: { label: "저장", icon: "save" },
                                on: { btnClicked: _vm.saveRowIn },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.data.equipInList.length > 0
                            ? _c("c-btn", {
                                attrs: { label: "삭제", icon: "remove" },
                                on: { btnClicked: _vm.removeRowIn },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
            [
              _c(
                "c-table",
                {
                  ref: "grid2",
                  attrs: {
                    title: "부품 출고 이력",
                    columns: _vm.grid2.columns,
                    data: _vm.data.equipOutList,
                    gridHeight: _vm.grid2.height,
                    editable: _vm.editable && !_vm.disabled,
                    pagePerRow: { pageNumber: 10 },
                    hideBottom: false,
                    usePaging: true,
                    isExcelDown: true,
                    filtering: true,
                    isFullScreen: true,
                    columnSetting: false,
                    selection: "multiple",
                    rowKey: "equipmentOutCd",
                    topBorderClass: "topcolor-orange",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && _vm.popupParam.equipmentBomCd
                            ? _c("c-btn", {
                                attrs: { label: "추가", icon: "add" },
                                on: { btnClicked: _vm.addRowOut },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.data.equipOutList.length > 0
                            ? _c("c-btn", {
                                attrs: { label: "저장", icon: "save" },
                                on: { btnClicked: _vm.saveRowOut },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.data.equipOutList.length > 0
                            ? _c("c-btn", {
                                attrs: { label: "삭제", icon: "remove" },
                                on: { btnClicked: _vm.removeRowOut },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }