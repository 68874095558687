<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card class="cardClassDetailForm" title="상세">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :disabled="true"
                  label="설비명"
                  name="equipmentName"
                  v-model="data.equipmentName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :disabled="true"
                  label="자재명"
                  name="equipmentBomName"
                  v-model="data.equipmentBomName">
                </c-text>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :disabled="true"
                  label="개수"
                  name="unit"
                  type="number"
                  v-model="data.unit">
                </c-text>
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :disabled="true"
                  :editable="editable"
                  codeGroupCd="MATERIAL_UNIT_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="materialUnitCd"
                  label="단위"
                  v-model="data.materialUnitCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="false"
                  label="현재 재고량"
                  type="number"
                  name="realStock"
                  v-model="realStock">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <c-table
            ref="grid1"
            title="부품 입고 이력"
            :columns="grid1.columns"
            :data="data.equipInList"
            :gridHeight="grid1.height"
            :editable="editable&&!disabled"
            :pagePerRow="{pageNumber:10}"
            :hideBottom="false"
            :usePaging="true"
            :isExcelDown="true"
            :filtering="true"
            :isFullScreen="true"
            :columnSetting="false"
            topBorderClass="topcolor-orange"
            selection="multiple"
            rowKey="equipmentInCd"
            @table-data-change="tableDataChangeIn"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="추가" icon="add" @btnClicked="addRowIn" v-if="editable && popupParam.equipmentBomCd" />
                <c-btn label="저장" icon="save" @btnClicked="saveRowIn" v-if="editable && data.equipInList.length > 0" />
                <c-btn label="삭제" icon="remove" @btnClicked="removeRowIn" v-if="editable && data.equipInList.length > 0" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-table
            ref="grid2"
            title="부품 출고 이력"
            :columns="grid2.columns"
            :data="data.equipOutList"
            :gridHeight="grid2.height"
            :editable="editable&&!disabled"
            :pagePerRow="{pageNumber:10}"
            :hideBottom="false"
            :usePaging="true"
            :isExcelDown="true"
            :filtering="true"
            :isFullScreen="true"
            :columnSetting="false"
            selection="multiple"
            rowKey="equipmentOutCd"
            topBorderClass="topcolor-orange"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="추가" icon="add" @btnClicked="addRowOut" v-if="editable && popupParam.equipmentBomCd" />
                <c-btn label="저장" icon="save" @btnClicked="saveRowOut" v-if="editable && data.equipOutList.length > 0" />
                <c-btn label="삭제" icon="remove" @btnClicked="removeRowOut" v-if="editable && data.equipOutList.length > 0" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-spare-history-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentBomCd: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      data: {
        plantCd: null,  // 사업장코드
        materialNo: '',
        equipmentBomCd: '',  // 정비자재 일련번호
        materialName: '',  // 품명
        materialUnitCd: null,  // 부품 단위
        materialStandard: '',  // 부품 규격
        materialQuality: '',  // 부품 재질
        price: null,  // 대표단가
        vendorCd: '',  // 제조사
        safetyStock: '',  // 안전재고량
        realStock: '',  // 현재재고량
        ropStock: '',  // 재주문점(ROP)
        supplantMaterialCd: '',  // 대체자재
        equipInList: [], // 입고이력
        equipOutList: [], // 출고이력
      },
      grid1: {
        columns: [
          {
            name: 'inDt',
            field: 'inDt',
            label: '입고일',
            align: 'center',
            type: 'date',
            sortable: true,
            style: 'width:140px',
          },
          {
            name: 'vendorCd',
            field: 'vendorCd',
            label: '공급업체',
            align: 'left',
            type: 'vendor',
            sortable: true,
          },
          {
            name: 'inStock',
            field: 'inStock',
            label: '입고수량',
            type: 'number',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'inUnitPrice',
            field: 'inUnitPrice',
            label: '단가',
            type: 'number',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'inTotalPrice',
            field: 'inTotalPrice',
            label: '총비용',
            type: 'cost',
            style: 'width:150px',
            sortable: true,
          },
        ],
        data: [],
        height: '500px',
      },
      grid2: {
        columns: [
          {
            name: 'outDt',
            field: 'outDt',
            label: '출고일',
            align: 'center',
            type: 'date',
            sortable: true,
            style: 'width:150px',
          },
          {
            name: 'outStock',
            field: 'outStock',
            label: '출고수량',
            type: 'number',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
        height: '500px',
      },
      detailUrl: '',
      inSaveUrl: '',
      inDeleteUrl: '',
      outSaveUrl: '',
      outDeleteUrl: '',
      realStock: 0,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {

      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.mdm.equipment.spareParts.get.url;

      this.inSaveUrl = transactionConfig.mdm.equipment.spareParts.in.save.url;
      this.inDeleteUrl = transactionConfig.mdm.equipment.spareParts.in.delete.url;
      this.outSaveUrl = transactionConfig.mdm.equipment.spareParts.out.save.url;
      this.outDeleteUrl = transactionConfig.mdm.equipment.spareParts.out.delete.url;
      
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.equipmentBomCd) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.equipmentBomCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.calculRealStock();
        },);
      }
    },
    addRowIn() {
      this.data.equipInList.splice(0, 0, {
        plantCd: this.data.plantCd,  // 사업장코드
        equipmentInCd: uid(),
        equipmentBomCd: this.popupParam.equipmentBomCd,  // 정비자재 일련번호
        inDt: this.$comm.getToday(),  // 입고일
        vendorCd: '',  // 공급업체
        inStock: '',  // 입고수량
        inUnitPrice: '',  // 단가
        inTotalPrice: '',  // 총 비용
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: this.$store.getters.user.userId,  // 수정자 ID
        editFlag: 'C'
      })
    },
    saveRowIn() {
      if (this.$comm.validTable(this.grid1.columns, this.data.equipInList)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.inSaveUrl;
            this.$http.type = 'POST';
            this.$http.param = this.data.equipInList;
            this.$http.request(() => {
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '저장되었습니다.', /* 저장되었습니다. */
                type: 'success', // success / info / warning / error
              });
              this.getDetail();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    removeRowIn() {
      let selectData = this.$refs['grid1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.inDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.data.equipInList = this.$_.reject(this.data.equipInList, item);
              })
              this.$refs['grid1'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRowOut() {
      if (this.$comm.validTable(this.grid2.columns, this.data.equipOutList)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.outSaveUrl;
            this.$http.type = 'POST';
            this.$http.param = this.data.equipOutList;
            this.$http.request(() => {
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '저장되었습니다.', /* 저장되었습니다. */
                type: 'success', // success / info / warning / error
              });
              this.getDetail();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addRowOut() {
      this.data.equipOutList.splice(0, 0, {
        plantCd: this.data.plantCd,  // 사업장코드
        equipmentOutCd: uid(),
        outDt: this.$comm.getToday(), 
        workOrderCd: '',
        workOrderName: '',
        outStock: '',
        equipmentBomCd: this.popupParam.equipmentBomCd,  // 정비자재 일련번호
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: this.$store.getters.user.userId,  // 수정자 ID
        editFlag: 'C'
      })
    },
    removeRowOut() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.outDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.data.equipOutList = this.$_.reject(this.data.equipOutList, item);
              })
              this.$refs['grid2'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    tableDataChangeIn(props) {
      if (props.row['inUnitPrice'] > 0 && props.row['inStock'] > 0) {
        this.$set(props.row, 'inTotalPrice', props.row['inStock'] * props.row['inUnitPrice']);
      }
      this.calculRealStock();
    },
    tableDataChangeOut(props) {
      if (props.row['inUnitPrice'] > 0 && props.row['inStock'] > 0) {
        this.$set(props.row, 'inTotalPrice', props.row['inStock'] * props.row['inUnitPrice']);
      }
      this.calculRealStock();
    },
    calculRealStock() {
      let sumInStock = 0;
      let sumOutStock = 0;
      this.$_.forEach(this.data.equipInList, _item => {
        sumInStock += _item.inStock
      })
      this.$_.forEach(this.data.equipOutList, _item => {
        sumOutStock += _item.outStock
      })

      this.realStock = sumInStock - sumOutStock;
    }
  }
};
</script>